@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

:root {
  --black: #202124;
  --white: #e8eaed;
  --neon-green: #39FF14;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Oswald';
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Oswald', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

